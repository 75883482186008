import axios from "axios";

const server = process.env.REACT_APP_STAGE_SERVER
// console.log("VALUEEE: ", process.env, server)
const SATGE_V2_API = `${server}/`

const axiosInstance = axios.create({
  baseURL: SATGE_V2_API,
  timeout: 1000 * 60 * 5,
  headers: { "Content-Type": "application/json", 'Accept': 'application/json', "Authorization": `Bearer ${localStorage.getItem('token')}` },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance