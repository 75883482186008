import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { counterSlice } from "redux/counter";
import { AppDispatch } from "redux/store";
import { useSelector } from "react-redux";

function AnimationPage() {
  const navigate = useNavigate();

  const { number } = useSelector((state) => state.counter);

  const handleClick = () => {
    const dispatch = AppDispatch();
    dispatch(
      counterSlice.actions.feedbackStatusLoad({
        loader: false,
        rating: false,
        animation: false,
        share: true,
        thankYou: false,
      })
    );
    navigate("/share");
  };

  const handleDone = (e) => {
    const dispatch = AppDispatch();
    dispatch(
      counterSlice.actions.feedbackStatusLoad({
        loader: false,
        rating: false,
        animation: false,
        share: false,
        thankYou: true,
      })
    );
    navigate(`/ThankYou`);
  };
  return (
    <div
      style={{
        alignSelf: "center",
        justifySelf: "center",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "auto",
        display: "flex",
        // maxHeight: "100vh",
        // width: "300px",
        maxWidth: "700px",
        flexDirection: "column",

        padding: "2rem",
        overflow: "hidden",
        paddingBlock: "2rem",
      }}
    >
      <Box style={{ flex: 1, paddingBlock: 10 }}>
        <img
          style={{
            height: "15vh",

            position: "relative",
            overflow: "hidden",
          }}
          src={"/assets/campaign_logo.png"}
        ></img>
      </Box>

      <Typography
        style={{
          fontFamily: "Poppins",
          flex: 1,
          fontWeight: "bolder",
          fontSize: "1.3rem",
        }}
      >
        Most disposable plastics end up being tossed into water bodies killing
        countless aquatic wildlife.
      </Typography>
      <div style={{ flex: 1 }}>
        <img
          style={{
            width: "292px",
            height: "210px",
            position: "relative",
            overflow: "hidden",
          }}
          src={"/assets/smile_drop.gif"}
          alt="smile_drop"
        />
      </div>

      <div style={{ flex: 1 }}>
        <Typography
          style={{
            fontFamily: "Poppins",
            color: "#1BC831",
            fontWeight: "bolder",
            fontSize: "1.2rem",
          }}
        >
          Thanks for reducing that by one and saving a friend.
        </Typography>
        {number === 1 || number === 2 ? (
          <Button
            onClick={handleDone}
            sx={{ boxShadow: 3 }}
            style={{
              fontSize: "1.2rem",
              fontFamily: "Poppins",
              fontWeight: "bolder",
              width: "7rem",
              margin: "1rem",
              height: "3rem",
              cursor: "pointer",
              borderRadius: "5rem",
              color: "black",
              padding: 10,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            Done
          </Button>
        ) : (
          <Button
            sx={{ boxShadow: 3 }}
            onClick={handleClick}
            style={{
              maxHeight: "3rem",
              backgroundColor: "#1BC831",
              elevation: 10,
              marginTop: "2rem",
              paddingInline: "15",
              borderRadius: "5rem",
              color: "black",
              boxShadow: "10px",
              fontFamily: "Poppins",
              fontWeight: "bolder",
              fontSize: "1.2rem",
              paddingInline: 15,
              textTransform: "none",
            }}
          >
            Meet your friend
          </Button>
        )}
      </div>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          marginBlock: "2rem",
        }}
      >
        <Typography style={{ fontFamily: "Poppins", fontSize: "0.9rem" }}>
          Mission By
        </Typography>
        <div>
          <img
            style={{ width: "105px", height: "45px" }}
            src={"/assets/Infinity_logo_black.png"}
          ></img>
        </div>
      </Box>
    </div>
  );
}

export default AnimationPage;
