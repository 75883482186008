import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, Chip, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getRatingOptions, postUpdateFeedback } from "Apis";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { counterSlice } from "redux/counter";
import { AppDispatch } from "redux/store";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";

const useStyles = makeStyles({
  root: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
  "icon-1": { color: "#F89A9A" },
  "icon-2": { color: "#EDCC9B" },
  "icon-3": { color: "#E4B2F0" },
  "icon-4": { color: "#A5BDED" },
  "icon-5": { color: "#19BEA0" },
});

export default function BasicRating() {
  const navigate = useNavigate();
  const classes = useStyles();

  const [hover, setHover] = React.useState(-1);
  const [value, setValue] = useState(0);
  const [text, setText] = useState("");
  const [word, setWord] = useState("");
  const [comments, setComments] = useState([]);
  const [allcomments, setAllComments] = useState([]);
  const [options, setoptions] = useState([]);
  let chipMenuSelected = [];
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const dispatch = AppDispatch();

  useEffect(() => {
    if (value === 1) {
      setWord("Ewww!!");
    } else if (value === 2) {
      setWord("Terrible");
    } else if (value === 3) {
      setWord("Decent");
    } else if (value === 4) {
      setWord("Great!");
    } else if (value === 5) {
      setWord("Flawless!");
    }
    if (value > 3) {
      setText("Please tell us what was good:");

      setComments(allcomments.goodComments);
    } else if (value > 0) {
      if (value === 3) {
        setText("Please tell us what can we improve:");
      } else {
        setText("Please tell us what went wrong:");
      }
      setComments(allcomments.badComments);

      // setText("Please tell us what went wrong:");
      // setComments(allcomments.badComments);
    } else {
      setComments([]);
    }
  }, [value]);

  useEffect(() => {
    const getRatings = async () => {
      const data = await getRatingOptions();
      // console.log(data);
      setAllComments({
        goodComments: data.slice(4, 7),
        badComments: data.slice(0, 4),
      });
    };
    getRatings();
  }, []);

  const handleClick = async () => {
    var unique = chipMenuSelected.filter((v, i, a) => a.indexOf(v) === i);
    const data = unique.toString();
    // console.log("data", data);
    // const res = getPostUpdateFeedback(value, data);
    const res = await postUpdateFeedback(value, data);
    // const res = await postUpdateFeedback(value, data);
    // console.log("what is the url", res.badgeUrl);

    dispatch(counterSlice.actions.updateUrlSuccess(res.badgeUrl));

    ReactGA.event({
      category: "User feedback given",
      action: "RATED",

      value: value, // optional, must be a number
    });

    dispatch(
      counterSlice.actions.feedbackStatusLoad({
        loader: false,
        rating: false,
        animation: true,
        share: false,
        thankYou: false,
      })
    );
    navigate("/rated");
  };

  const handleChipClick = (event) => {
    let isSelected = false;
    // console.log(chipMenuSelected.includes(event.currentTarget.id));
    if (!chipMenuSelected.includes(event.currentTarget.id)) {
      //checking weather array contain the id
      chipMenuSelected.push(event.currentTarget.id); //adding to array because value doesnt exists
      isSelected = true;
      event.target.style.backgroundColor = "#1BC831";
      event.target.style.color = "white";
      event.target.style.borderWidth = 0;
      event.target.style.boxShadow = "10px";
    } else {
      chipMenuSelected.splice(
        chipMenuSelected.indexOf(event.currentTarget.id),
        1
      );
      event.target.style.backgroundColor = "#FFFFFF";
      event.target.style.color = "black";
      event.target.style.border = "thin solid #000000";
    }
    // const att = event.target.getAttribute('color');
    // console.log("att",att)
    // event.target.setAttribute('color', 'success');
    // setoptions(temp)
    // console.log("did I click", chipMenuSelected);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          // margin: "auto",

          maxHeight: "1500px",
          // width: "300px",
          // maxWidth: "700px",
          flexDirection: "column",
          backgroundColor: "#ECF3ED",
          justifyContent: "space-between",
          padding: "2rem",
        }}
      >
        <Box style={{ flex: 2, marginBottom: "1rem" }}>
          <img
            style={{
              height: "15vh",

              position: "relative",
              overflow: "hidden",
            }}
            src={"/assets/campaign_logo.png"}
          ></img>
        </Box>
        <Card
          elevation={10}
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifySelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 4,

            marginTop: "2rem",
            // height: "800px",
            maxHeight: "800px",
            width: "300px",
            maxWidth: "700px",
            borderRadius: "5%",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              marginBlock: 5,
            }}
          >
            <div
              style={{
                width: "100px",
                height: 2,
                backgroundColor: "black",
                marginRight: "5%",
              }}
            ></div>
            <img
              style={{ maxWidth: "48px", maxHeight: "33px" }}
              src={"/assets/box_icon.png"}
            ></img>
            <div
              style={{
                width: "100px",
                height: 2,
                backgroundColor: "black",
                marginLeft: "5%",
              }}
            ></div>
          </div>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: "bolder",
              marginBlock: 5,
              fontSize: "1.2rem",
            }}
          >
            How would you rate your packaging experience?
          </Typography>

          <Typography
            style={{
              fontFamily: "Gluten",
              fontWeight: "bolder",
              fontSize: "1.7rem",

              color:
                word === "Ewww!!"
                  ? "#F89A9A"
                  : word === "Terrible"
                  ? "#EDCC9B"
                  : word === "Decent"
                  ? "#E4B2F0"
                  : word === "Great!"
                  ? "#A5BDED"
                  : word === "Flawless!"
                  ? "#19BEA0"
                  : "black",
              marginBlock: 5,
            }}
          >
            <div className="fade">{word}</div>
          </Typography>

          <Rating
            classes={{
              iconHover: classes[`icon-${hover}`],
              iconFilled: classes[`icon-${value}`],
            }}
            // name="simple-controlled"
            value={value}
            size={"large"}
            onChange={(event, newValue) => {
              setValue(newValue);
              dispatch(counterSlice.actions.updateRatingSuccess(newValue));
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            icon={<StarRateRoundedIcon fontSize="inherit" />}
          />
          <div className="fadeIn">
            <Typography
              style={{
                fontFamily: "Poppins",
                fontWeight: "bolder",
                marginBlock: 4,
              }}
            >
              {text}
            </Typography>
          </div>

          {comments && (
            <Box>
              {comments.map((el, idx) => {
                return (
                  <button
                    sx={{ boxShadow: 3 }}
                    onClick={handleChipClick}
                    id={el.id}
                    style={{
                      padding: 9,
                      outline: 0,
                      margin: 7,
                      textAlign: "center",
                      cursor: "pointer",

                      borderRadius: 18,
                      borderWidth: 1,
                      borderColor: "#505050",
                      backgroundColor: "white",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                    }}
                  >
                    {el.label}
                  </button>
                );
              })}
            </Box>
          )}
        </Card>
        <Button
          sx={{ boxShadow: 3 }}
          disabled={value === 0}
          onClick={handleClick}
          style={{
            backgroundColor: "#1BC831",
            marginTop: "2rem",
            width: "10rem",
            alignSelf: "center",
            borderRadius: "5rem",

            color: "black",
            fontFamily: "Poppins",
            fontWeight: 900,
            fontSize: "1.2rem",
            textTransform: "capitalize",
          }}
        >
          Submit
        </Button>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginBlock: "2rem",
          }}
        >
          <Typography style={{ fontFamily: "Poppins", fontSize: "0.9rem" }}>
            Mission By
          </Typography>
          <div>
            <img
              style={{ width: "105px", height: "45px" }}
              src={"/assets/Infinity_logo_black.png"}
            ></img>
          </div>
        </Box>
      </div>
    </div>
  );
}
