import React from "react";
import "../styles/NotFoundPage.css";
import { Card, CardContent, Typography } from "@mui/material";

function LandingPage() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Card style={{}}>
        <CardContent>
          <Typography gutterBottom variant="h3" component="div">
            Hello There!!!
          </Typography>
          <Typography gutterBottom variant="h4" component="div">
            Do <a href="https://getinfinitybox.com/">check us out</a>. Join our
            hands to save our planet!
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default LandingPage;
