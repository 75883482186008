import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import BasicRating from "./Pages/Rating";
import "./App.css";
import AnimationPage from "./Pages/AnimationPage";
import Share from "./Pages/Share";
import { getStatus } from "./Apis";
import Loader from "Pages/Loader";
import NotFoundPage from "Pages/NotFoundPage";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { counterSlice } from "redux/counter";
import Visited from "Pages/Visited";
import { useNavigate } from "react-router-dom";
import ThankYou from "Pages/ThankYou";
import LandingPage from "Pages/LandingPage";

// import { getFeedbackStatus } from "./redux/counter";
import ReactGA from "react-ga4";

ReactGA.initialize("G-YM2Q6VFMKG");
ReactGA.send("pageview");

let temp;
function App() {
  const [errorPage, setErrorPage] = useState(false);
  const [root, setRoot] = useState(false);
  const [tempUid, setTempUid] = useState();
  const location = window.location.href;
  let navigate = useNavigate();

  const { isLoading, rating, animation, share, thankYou, feedbackStatus } =
    useSelector((state) => state.counter);

  useEffect(() => {
    const check = async () => {
      const uid = location.replaceAll(`${process.env.REACT_APP_SERVER}`, "");
      // const token = await postAccessToken();
      // console.log("UID", uid);
      temp = uid.split(".com/");
      // console.log("token", token);
      // console.log("uid", temp);

      // localStorage.setItem("token", token)
      localStorage.setItem("uid", temp[0]);
      if (temp[0] != "") {
        setRoot(false);

        setTempUid(temp[0]);
        // const tempData = await dispatch(getFeedbackStatus(temp[0]))
        const data = await getStatus(temp[0]);
        // console.log("data", data);
        if (!data) {
          setErrorPage(true);
        } else if (data.ratingCode === 1) {
          const dispatch = AppDispatch();
          dispatch(
            counterSlice.actions.feedbackStatusLoad({
              loader: false,
              rating: false,
              animation: true,
              share: false,
              thankYou: false,
            })
          );
          navigate("/rated");
        }
      }
      if (temp[0] == "") {
        setRoot(true);
      }
    };
    check();
  }, []);

  return (
    <div>
      {errorPage && <NotFoundPage />}
      {root && <LandingPage />}
      <div className="App">
        <Routes>
          {isLoading && <Route path={`${tempUid}/`} element={<Loader />} />}
          {rating && <Route path={`${tempUid}/`} element={<BasicRating />} />}
          {animation && <Route path={`/rated`} element={<AnimationPage />} />}
          {share && <Route path="/share" element={<Share />} />}
          {thankYou && <Route path="/thankYou" element={<ThankYou />} />}
          {/* {root && <Route path="/" element={<LandingPage />} />} */}
        </Routes>
      </div>
    </div>
  );
}

export default App;
